//--------- Start Filter Bar -----------//
.filter-bar {
  padding: 0px 20px 10px 20px;
  background: #f1f6f7;
  margin-bottom: 30px;

  a, i {
    color: $black;
  }

  &-search {
    margin-top: 10px;

    input {
      border: 1px solid #eee;
      font-size: 14px;
      color: #999999;
      height: 38px;
      padding-left: 15px;

      &:focus {
        outline: 0;
        box-shadow: none;
      }

      &::placeholder {
        color: #999999;
      }
    }

    button {
      background: transparent;
      border: 1px solid #eee;
      background: #fff;
      border-left: 0;
      padding-right: 15px;

      i, span {
        font-size: 14px;
        color: #999999;
      }
    }
  }
}

//--------- Start storting -----------//
.sorting {
  margin-top: 10px;
  margin-right: 10px;

  .nice-select {
    border-radius: 0px;
    border: 1px solid #eee;
    padding-right: 50px;
    height: 38px;
    color: #999999;

    &:after {
      right: 18px;
    }

    .list {
      border-radius: 0px;
      max-height: 400px;
      overflow-y: auto;
    }
  }
}


//--------- Start Sidebar Category Dropdown ---------//
.sidebar-categories {
  .head {
    font-family: $text-font;
    line-height: 50px;
    background: $primary-color;
    padding: 0 30px;
    font-size: 18px;
    font-weight: 400;
    color: #fff;
  }

  .main-categories {
    padding: 20px 28px;
    background: #f1f6f7;
    // box-shadow: 0 10px 10px rgba(153, 153, 153, 0.1);

    .pixel-radio {
      background: transparent !important;
    }
  }

  .main-nav-list {
    a {
      font-size: 14px;
      display: block;
      line-height: 50px;
      padding-left: 10px;
      border-bottom: 1px solid #eee;

      &:hover {
        color: $primary-color;
      }

      .number {
        color: #cccccc;
        margin-left: 10px;
      }

      .lnr {
        margin-right: 10px;
        display: none;
      }
    }

    &.child {
      a {
        padding-left: 32px;
      }
    }
  }
}

//--------- End Sidebar Category Dropdown ---------//


//--------- Start Product Filter ---------//
.filter-reset-btn{
  background-color: $primary-color;
  border: none;
  color: $white;
  font-family: $title-font;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  width: 100%;
}
.sidebar-filter {
  margin-top: 30px;
  .top-filter-head {
    font-family: $text-font;
    line-height: 50px;
    background: $primary-color;
    padding: 0 30px;
    font-size: 18px;
    font-weight: 400;
    color: #fff;
  }
  .head {
    line-height: 60px;
    padding: 0 30px;
    font-size: 15px;
    font-weight: 400;
    color: $title-color;
    text-transform: capitalize;
    // margin-top: 5px;
  }

  .common-filter {
    background: #f1f6f7;
    border-bottom: 1px solid #eee;
    padding-bottom: 25px;

    .filter-list {
      position: relative;
      padding-left: 28px;
    }
  }

  .common-filter:last-child {
    border-bottom: 0;
  }
}

.filter-list {
  line-height: 32px;

  i {
    margin-right: 10px;
    cursor: pointer;
  }

  .number {
    color: #ccc;
  }

  label {
    margin-bottom: 3px;
    cursor: pointer;
    font-size: 14px;
  }
}

@-webkit-keyframes click-wave {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.35;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  100% {
    transform: translate(-50%, -50%) scale(3);
    opacity: 0;
    top: 50%;
    left: 50%;
    position: absolute;
  }
}

@-moz-keyframes click-wave {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.35;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  100% {
    transform: translate(-50%, -50%) scale(3);
    opacity: 0;
    top: 50%;
    left: 50%;
    position: absolute;
  }
}

@keyframes click-wave {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.35;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  100% {
    transform: translate(-50%, -50%) scale(3);
    opacity: 0;
    top: 50%;
    left: 50%;
    position: absolute;
  }
}

.pixel-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  height: 15px;
  width: 15px;
  -webkit-transition: all 0.15s ease-out 0s;
  -moz-transition: all 0.15s ease-out 0s;
  transition: all 0.15s ease-out 0s;
  background: #fff;
  border: 1px solid #999999;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  outline: none;
  position: relative;
  z-index: 1;
}

.pixel-radio:checked {
  border: 8px solid $primary-color;
}

.pixel-radio:checked::after {
  -webkit-animation: click-wave 0.65s;
  -moz-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: $primary-color;
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  opacity: 0;
}

.pixel-radio {
  border-radius: 50%;
  top: 2px;
}

.pixel-radio::after {
  border-radius: 50%;
}

//--------- End Product Filter ---------//


//--------- Start Price Range Area noUiSlider ----------//
.price-range-area {
  padding: 15px 30px 0 30px;

  .noUi-target {
    background: #eee;
    border-radius: 0px;
    border: 0px;
    box-shadow: none;
  }

  .noUi-base {
    background: #e8f0f2;
  }

  .noUi-horizontal {
    height: 6px;
  }

  .noUi-connect {
    background: #e8f0f2;
    border-radius: 0px;
    box-shadow: none;
  }

  .noUi-horizontal .noUi-handle {
    width: 16px;
    height: 16px;
    left: -8px;
    top: -5px;
    border-radius: 50%;
    border: 0px;
    background: $primary-color;
    box-shadow: none;
    cursor: pointer;
    @include transition(ease .1s);

    &:focus {
      outline: none;
    }

    &:hover {
      border: 3px solid $primary-color;
      background: $white;
    }
  }

  .noUi-handle::after, .noUi-handle::before {
    display: none;
  }

  .value-wrapper {
    margin-top: 15px;
    color: #777777;

    .to {
      margin: 0 5px;
    }
  }

  .price {
    margin-right: 5px;
    color: $text-color;
  }
}

//--------- End Price Range Area noUiSlider ----------//


//--------- single top product ----------//
.single-search-product {
  padding: 10px 0;
  @media(min-width: 1000px) {
    padding: 20px 0;
  }

  &-wrapper {
    padding: 12px 20px 10px 30px;
    border: 1px solid #EEEEEE;
  }

  img {
    max-width: 70px;
    height: auto;
  }

  .desc {
    margin-left: 15px;
    margin-top: 5px;

    .title {
      font-family: $text-font;
      color: $title-color;
      margin-bottom: 0;
      display: inline-block;
      // background: -webkit-linear-gradient(-180deg, #555555, #555555);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
      &.red{
        color: $primary-color;
      }
    }
    .autor{
      font-size: 15px;
      color: #777777;
      font-family: $title-font;
    }
    .price {
      font-size: 16px;
      font-weight: 500;
      color: #777777;

      span {
        font-weight: 700;
      }

      del {
        font-size: 12px;
        color: #cccccc;
        font-weight: 400;
      }
    }
  }

  &:hover {
    .title {
      color: $primary-color;
    }
  }

  // position: relative;
  // &:after {
  // 	position: absolute;
  // 	content: "";
  // 	width: 100%;
  // 	height: 1px;
  // 	background: #eeeeee;
  // 	left: 0;
  // 	bottom: 0;
  // 	z-index: 1;
  // }
  // &:before {
  // 	position: absolute;
  // 	content: "";
  // 	width: 0%;
  // 	height: 1px;
  // 	// @extend .p1-gradient-bg;
  // 	left: 0;
  // 	bottom: 0;
  // 	z-index: 2;
  // 	@include transition (all .3s ease);
  // }
  // &:hover {
  // 	&:before {
  // 		width: 100%;
  // 	}
  // }
}

@media (max-width: 1200px) {
  .lattest-product-area .card-product__img {
    height: 255px;
  }
}

@media (max-width: 991px) {
  .lattest-product-area .card-product__img {
    height: 460px;
  }
}

.autors-page {
  .card {
    box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
    max-width: 270px;
    margin: 0 auto 30px auto;
  }

  .card-product__img {
    height: 240px;
    max-width: unset;
    margin: 0;

    img {
      object-fit: contain;
    }
  }
}

.letter-filter {
  border-bottom: 1px solid $primary-color;

  a {
    color: $black;

    &:hover {
      color: $primary-color;
    }
  }
}
.out-of-stock{
  font-size: 13px;
  font-family: $title-font;
  color: $baseColor;

}
