/*============== footer-area css ===============*/
.footer-area {
  background: $dip;
  padding: 50px 0px 50px;

  @media(min-width: 1000px) {
    padding: 195px 0px 120px;
  }
}

.single-footer-widget {
  p {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .instafeed {
    margin-left: -5px;
    margin-right: -5px;

    li {
      margin: 5px;

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        @media (max-width: 1199px) and (min-width: 992px) {
          display: none;
        }
      }
    }
  }

  .sm-head {
    color: $white;
    margin-bottom: 0px;

    span {
      position: relative;
      margin-left: -40px;
      margin-right: 10px;
      display: inline-block;
      width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      background: $primary-color;
      color: #ffffff;
      border-radius: 20px;
    }
  }
}

.tp_widgets {
  .list {
    li {
      font-family: $title-font;
      margin-bottom: 12px;

      a {
        color: #7b838a;

        &:hover {
          color: $primary-color;
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  p {
    font-family: $title-font;
    color: #7b838a;
  }
}

.footer_title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  font-family: $title-font;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 28px;
  @media (max-width: 991px) {
    margin-bottom: 10px;
  }

  &.large_title {
    font-size: 36px;
    font-weight: 600;
    @media (max-width: 1199px) {
      font-size: 18px;
    }
  }
}

.footer-bottom {
  background: #fff;
  padding: 20px;

  .footer-text {
    color: #222;
    font-size: 14px;
    margin-bottom: 0;
    font-family: $title-font;

    a {
      color: $primary-color;
      opacity: 1;
    }
  }
}

.footer-only {
  padding-top: 60px !important;
  padding-bottom: 60px !important;

  @media(min-width: 1000px) {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
}

.logo-side img {
  height: 142px;
  margin-bottom: 15px;
}

.footer-fallow {
  font-family: $title-font;

  a {
    display: flex;
    align-items: center;
    color: #fff;
  }

  .fb {
    display: inline-block;
    padding: 5px;
    width: 30px;
    height: 30px;
    background-color: #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-left: 5px;
    background-image: url('/assets/vendors/themify-icons/SVG/facebook.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
}

//--------- end footer Area -------------//
