/*=================== section intro css start ====================*/
.section-intro{

  p{
    margin-bottom: 3px;
  }

  &__style{
    border-bottom: 2px solid $primary-color;
    padding-bottom: 8px;
    display: inline-block;
    margin-top: 10px;
  }
}
/*=================== section intro css end ====================*/


