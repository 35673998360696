/*=================== contact banner start ====================*/
.contact-banner{
  position: relative;

  h4,h1,a{
    text-transform: uppercase;
    color: #fff;
  }

  h4{
    font-size: 18px;
    margin-bottom: 15px;
  }

  h1{
    font-size: 37px;
    margin-bottom: 5px;

    @media(min-width: 992px){
      font-size: 48px;
    }
  }

  &-area{
    height: 280px;
    background: url('../../assets/images/contact-bg.png') center center no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;

    @media(min-width: 991px){
      height: 450px;
    }

    .contact-banner{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
    }


    &::after{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(1, 2, 11, 0.7);
      z-index: -1;
    }
  }

  &-btn{
    border:2px solid $primary-color;
    padding: 10px 45px;

    &:hover{
      background: $primary-color;
      color: #fff;
    }
  }
}

.contact-info{

  &__icon{
    margin-right: 20px;

    i,span{
      color: $primary-color;
      font-size: 20px;
    }
  }

  .media-body{

    h3{
      font-size: 16px;
      margin-bottom: 0;

      a{
        color: $title-color;
      }
    }

    p{
      color: #999999;
    }
  }
}
/*=================== contact banner end ====================*/


/*=================== contact form start ====================*/
.form-contact{

  .form-group{
    margin-bottom: 10px;
  }

  .form-control{
    border-radius: 0;
    height: 40px;
    padding-left: 18px;
    border-color: transparent;
    background: rgba(130, 139, 178, 0.1);

    &:focus{
      outline: 0;
      box-shadow: none;
    }

    &::placeholder{
      font-size: 14px;
      color: #999999;
    }
  }

  .different-control{
    height: 100% !important;
  }

  button{
    border: 0;
  }
}

/*=================== contact form end ====================*/
