/*=================== checkout page css start ====================*/
.checkout_area{
	
}
.check_title{
	h2{
		font-size: 15px;
		font-weight: normal;
		font-family: $text-font;
		background: #f1f6f7;
		line-height: 40px!important;
		padding-left: 30px;
		margin-bottom: 0px;
		a{
			color: $primary-color;
			text-decoration: underline;
		}
	}
}
.returning_customer{
	p{
		margin-top: 15px;
		padding-left: 30px;
		margin-bottom: 25px;
	}
	.contact_form{
		max-width: 710px;
		margin-left: 15px;
		.form-group{
			margin-bottom: 20px;
			input{
				border: 1px solid #eeeeee;
				height: 40px;
				border-radius: 3px;
				font-size: 14px;
				// font-family: $primary-font;
				color: #999999;
				font-weight: normal;
				@include placeholder{
					font-size: 14px;
					// font-family: $primary-font;
					color: #999999;
					font-weight: normal;
				}
			}
			.primary-btn{
				line-height: 38px;
				padding: 0px 38px;
				text-transform: uppercase;
				margin-right: 10px;
				border-radius: 2px;
				border: none;
			}
			.creat_account{
				display: inline-block;
				margin-left: 15px;
				input{
					height: auto;
					margin-right: 10px;
				}
			}
			.lost_pass{
				display: block;
				margin-top: 20px;
				font-size: 14px;
				// font-family: $primary-font;
				color: $text-color;
				font-weight: normal;
			}
		}
	}
}



// .p_star {
//     display: inline-block;
//     position: relative;
// }

// .p_star input {
//     background: #fff;
// }

//.p_star input:valid + .placeholder {
//    display: none;
//}
// .p_star input:focus + .placeholder{
//     display: none;
// }
// .p_star .placeholder {
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     left: 30px;
//     z-index: 1;
// }

// .p_star .placeholder::before {
//     content: attr(data-placeholder);
//     color: #777;
// }

// .p_star .placeholder::after {
//     content: " *";
//     color: tomato;
// }



.cupon_area{
	margin-bottom: 40px;
	input{
		margin-left: 30px;
		max-width: 730px;
		width: 100%;
		display: block;
		height: 40px;
		border-radius: 3px;
		padding: 0px 15px;
		border: 1px solid #eeeeee;
		outline: none;
		box-shadow: none;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.tp_btn{
		margin-left: 30px;
	}
}
.tp_btn{
	border: 1px solid #eeeeee;
	display: inline-block;
	line-height: 38px;
	padding: 0px 40px;
	color: $title-color;
	text-transform: uppercase;
	// font-family: $primary-font;
	font-weight: 500;
	border-radius: 3px;
	&:hover{
		background: $primary-color;
		color: #fff;
		border-color: $primary-color;
	}
}


.billing_details{
	h3{
		font-size: 18px;
		color: $title-color;
		border-bottom: 1px solid #eee;
		padding-bottom: 10px;
		margin-bottom: 30px;
	}
	.contact_form{
		.form-group{
      margin-bottom: 20px;
      .placeholder::before{
        font-size: 14px;
        color: #999999;
      }
			input{
        font-size: 14px;
				border-radius: 3px;
        color: #999999;
        border-color: #EEEEEE;
				@include placeholder{
          color: #999999;
          font-size: 14px;
				}
      }
      .form-control{
        border-color: #EEEEEE;
        font-size: 14px;
        color: #777777;
        
        &::placeholder{
          color: #777777;
        }
      }
      label{
        color: #999999;
      }
			textarea{
				height: 150px;
				border-radius: 3px;
				margin-top: 15px;
			}
			.country_select{
        width: 100%;
        height: 40px;
        border-color: #EEEEEE;
        .current{
          color: #999999;
        }
				span{
					color: $text-color;
				}
				.list{
					width: 100%;
					border-radius: 0px;
					li{
						font-size: 14px;
						// font-family: $primary-font;
						font-weight: normal;
					}
				}
			}
			.creat_account{
				#f-option2{
					margin-right: 5px;
				}
				#f-option3{
					margin-right: 5px;
				}
				a{
					color: $primary-color;
				}
			}
		}
	}
}
.order_box{
	background: #e8f0f2;
	padding: 30px;
	h2{
		border-bottom: 1px solid #dddddd;
		font-size: 18px;
		font-weight: 500;
		color: $title-color;
		padding-bottom: 15px;
	}
	.list{
		li{
			a{
				font-size: 14px;
				color: $text-color;
				font-weight: normal;
				border-bottom: 1px solid #eeeeee;
				display: block;
        line-height: 42px;
        h4{
          font-family: $text-font;
          font-size: 15px;
          margin-top: 15px;
        }
				span{
					float: right;
				}
				.middle{
					float: none;
					width: 50px;
					text-align: right;
					display: inline-block;
					margin-left: 30px;
					color: $title-color;
				}
			}
			&:nth-child(4){
				a{
					.middle{
						margin-left: 48px;
					}
				}
			}
		}
	}
	.list_2{
		li{
			a{
				text-transform: uppercase;
				color: $title-color;
				font-weight: 500;
				span{
					color: $text-color;
					text-transform: capitalize;
				}
			}
			&:last-child{
				a{
					span{
						color: $title-color;
					}
				}
			}
		}
	}
	.payment_item{
		h4{
			font-size: 14px;
			text-transform: uppercase;
			color: $title-color;
			font-weight: 500;
			padding-left: 22px;
			position: relative;
			margin-bottom: 15px;
			margin-top: 15px;
			&:before{
				content: "";
				width: 14px;
				height: 14px;
				border-radius: 50%;
				border: 1px solid #cdcdcd;
				background: #fff;
				display: block;
				position: absolute;
				left: 0px;
				top: 50%;
				transform: translateY(-50%);
			}
			&:after{
				content: "";
				height: 4px;
				width: 4px;
				background: #fff;
				border-radius: 50%;
				display: block;
				position: absolute;
				left: 5px;
				top: 8px;
			}
			img{
				padding-left: 60px;
			}
		}
		p{
			background: #fff;
      padding: 15px 20px;
      font-size: 12px;
		}
		&.active{
			h4{
				&:before{
					background: $primary-color;
					border-color: $primary-color;
				}
			}
		}
	}
	.creat_account{
		margin-bottom: 15px;
		label{
			padding-left: 10px;
			font-size: 12px;
			color: $title-color;
		}
		a{
			color: #c5322d;
		}
	}
	.primary-btn{
		display: block;
		line-height: 38px;
		text-transform: uppercase;
		text-align: center;
		border-radius: 2px;
		&:hover{
			&:before{
				left: 310px;
			}
		}
	}
}


.order_details{
	.title_confirmation{
		text-align: center;
		color: #28d500;
		font-size: 18px;
		margin-bottom: 80px;
	}
}
.order_d_inner{
	.details_item{
		h4{
			border-bottom: 1px solid #eee;
			padding-bottom: 12px;
			font-size: 18px;
			color: $title-color;
			padding-left: 18px;
		}
		.list{
			padding-left: 18px;
			@media (min-width: 767px) {
				margin-bottom: 30px;
			}
			li{
				margin-bottom: 8px;
				&:last-child{
					margin-bottom: 0px;
				}
				a{
					font-size: 14px;
					color: $title-color;
					// font-family: $primary-font;
					span{
						width: 145px;
						display: inline-block;
						color: $text-color;
					}
				}
			}
		}
	}
}
.order_details_table{
	background: #e5ecee;
	padding: 30px;
	margin-top: 75px;
	@media (min-width: 767px) {
		margin-top: 45px;
	}
	h2{
		color: $title-color;
		font-size: 18px;
		padding-bottom: 15px;
	}
	.table{
		margin-bottom: 0px;
		thead{
			tr{
				th{
					border-bottom: 1px solid #ddd;
					font-size: 14px;
					// font-family: $primary-font;
					font-weight: normal;
				}
			}
		}
		tbody{
			tr{
				td{
					p{
						margin-bottom: 0px;
					}
					h5{
						color: $title-color;
						margin-bottom: 0px;
					}
					h4{
						font-size: 14px;
						text-transform: uppercase;
						margin-bottom: 0px;
						color: $title-color;
					}
				}
				&:last-child{
					td{
						border-top: 1px solid #ddd;
						p{
							color: $title-color;
						}
					}
				}
			}
		}
	}
}

.radion_btn input[type=radio]{
  position: absolute;
  visibility: hidden;
}
.radion_btn{
  position: relative;
margin-top: 10px;
margin-bottom: 15px;
img{
  position: absolute;
  right: 40px;
  top: 0px;
}
}
.radion_btn label{
  display: block;
  position: relative;
  font-weight: 300;
  font-size: 1.35em;
  padding: 0px 25px 21px 25px;
  height: 14px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  // font-family: $primary-font;
font-weight: 500;
  color: $title-color;
  font-size: 15px;
  letter-spacing: .25px;
  text-transform: capitalize;
}
.radion_btn .check{
  display: block;
  position: absolute;
  border: 1px solid #cdcdcd;
  border-radius: 100%;
  height: 14px;
  width: 14px;
  top: 5px;
  left: 0px;
  background: #fff;
  z-index: 5;
  transition: border .25s linear;
  -webkit-transition: border .25s linear;
}

.radion_btn .check::before {
  display: block;
  position: absolute;
  content: '';
  border-radius: 100%;
  height: 4px;
  width: 4px;
  top: 4px; 
  left: 4px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

.radion_btn input[type=radio]:checked ~ .check {
  border: 1px solid #C5322D;
background: #C5322D;
}

.radion_btn input[type=radio]:checked ~ .check::before{
  background: #fff;
}

.radion_btn input[type=radio]:checked ~ label{
  color: #000;
}
.order_box .creat_account a{
  font-size: 12px;
  color: $primary-color;
}
/*=================== checkout page css end ====================*/
