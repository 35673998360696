/*=================== font family ====================*/
$title-font:'Dejavu Sans Condensed', sans-serif;
$text-font: 'DejaVu Sans Condensed Bold', sans-serif;


/*=================== color variations ====================*/
$title-color: #222222;
$text-color: #777777;
$primary-color: #cf3438;
$primary-color-blurry: #cf575a;
$white: #fff;
$link-color: #7b838a;

$primary-color2	: #ff6c00;
$primary-color3	: #828bb3;
$title-color	: #222222;
$text-color		: #777777;
$baseColor: #c5322d;
$dip: #222222;
$pfont: #777777;
$white			: #fff;
$black			: #000;
$offwhite: #fcfcfc;


/*=================== fonts ====================*/
@import url('https://cdn.web-fonts.ge/fonts/dejavu-sans-condensed/css/dejavu-sans-condensed.min.css');
@import url('https://cdn.web-fonts.ge/fonts/dejavu-sans-condensed-bold/css/dejavu-sans-condensed-bold.min.css');




