#photo-tab,
#video-tab{
  padding-bottom: 50px;
  .gallery-box{
    position: relative;
    margin-bottom: 20px;
    background-color: rgba(0,0,0,0.5);
    p{
      position: absolute;
      bottom: 0;
      left: 0;
      color: $white;
      background-color: rgba(0,0,0,0.8);
      padding: 5px 10px;
      margin: 0;
      font-size: 12px;
      width: 100%;
      font-family: $title-font;
      white-space: nowrap;
      overflow: hidden;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      -webkit-transition: .2s;
      -moz-transition: .2s;
      -ms-transition: .2s;
      -o-transition: .2s;
      transition: .2s;
    }
    &:hover p{
      visibility: hidden;
      opacity: 0;
    }
    img{
      width: 100%;
    }
  }
  img{
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
  }
  .gallery-item{
    img{
      object-fit: contain;
    }
  }
}
