/* =================================== */
/*  Basic Style 
/* =================================== */

body {
	color: $text-color;
	font-family: $text-font;
	font-size: 15px;
	font-weight: 400;
	line-height: 1.667;
}

/* =================================== */
/*  Typography 
/* =================================== */
ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a:hover{
  text-decoration: none;
}

button{
  cursor: pointer;

  &:focus{
    outline: 0;
    box-shadow: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $title-font;
	color: $title-color;
	line-height: 1.1;
}

h1,
.h1 {
  font-size: 50px;
  font-weight: 700;
}
h2,
.h2 {
  font-size: 36px;
  font-weight: 600;
}
h3,
.h3 {
  font-size: 26px;
  font-weight: 600;

  @media(min-width: 600px){
    font-size: 36px;
  }
}
h4,
.h4{
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
}

a{
  display: inline-block;
}

.fixed {
  background: #fff;
  position: fixed !important;
  top:0; left:0;
  width: 100% !important }

//  owl carousel img size hack
.owl-carousel .owl-stage {
  display: flex;
}

.section-margin{
  margin: 45px 0;

  @media(min-width: 980px){
    margin: 70px 0;
  }

  @media(min-width: 1100px){
    margin: 120px 0;

    &.calc-60px{
      margin: 120px 0 70px 0;
    }
  }

  &--small{
    margin: 35px 0;

    @media(min-width: 1000px){
      margin: 98px 0;
    }
  }
}
.section-padding{
  padding: 60px 0;

  @media(min-width: 1000px){
    padding: 130px 0;
  }
}

.pb-60px{
  padding-bottom: 60px;
}
.pt-60px{
  padding-top: 60px;
}
.ml-40{
  margin-left: 40px;
}