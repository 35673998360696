/* Login form Area css
============================================================================================ */
.login_box_area{
	.login_box_img{
    height: 350px;
		// margin-right: -30px;
		position: relative;
		
		@media(min-width: 1000px){
			height: 610px;
		}
    
    @media(min-width: 991px){
      margin-right: -30px;
    }
		img{
			width: 100%;
		}
		&:before{
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			content: "";
			background: $primary-color;
			opacity: .9;
		}
		.hover{
			position: absolute;
			top: 50%;
			left: 0px;
			text-align: center;
			width: 100%;
			transform: translateY(-50%);
			h4{
				font-size: 24px;
        color: #fff;
        font-weight: 500;
				margin-bottom: 15px;
			}
			p{
				max-width: 390px;
				margin: 0px auto 28px;
				color: #fff;
			}
			.primary-btn{
				border-radius: 0px;
				line-height: 38px;
				text-transform: uppercase;
				&:hover{
					&:before{
						left: 260px;
					}
				}
			}
		}

		.button:hover{
			background: #c5322d !important;
			color: #fff !important;
			border-color: #c5322d !important;
		}
	}
}
.login_form_inner{
	box-shadow: 0px 10px 40px 0px rgba(56, 74, 235, 0.1); 
	height: 100%;
	text-align: center;
	padding: 30px 0;

	@media(min-width: 1000px){
		padding-top: 115px;
		padding-bottom: 0;
	}
	h3{
		color: $title-color;
		text-transform: uppercase;
		font-size: 18px;
		margin-bottom: 75px;
	}
	.login_form{
		max-width: 385px;
		margin: auto;
		.form-group{
			
		}
	}
	&.reg_form{
		padding-top: 60px;
		h3{
			margin-bottom: 70px;
		}
	}
}
.login_form{
	.form-group{
    margin-bottom: 22px;
		input{
			height: 40px;
			border: none;
			border-bottom: 1px solid #cccccc;
			border-radius: 0px;
			outline: none;
      box-shadow: none;
      font-size: 14px;
      color: #999999;
			@include placeholder{
			  color: #999999;	
			}
		}
		.creat_account{
			margin-top: 10px;
      // margin-bottom: 15px;
      font-size: 14px;
			text-align: left;
			input{
				height: auto;
        margin-right: 10px;
        position: relative;
        top: 2px;
			}
		}
		.primary-btn{
			display: block;
			border-radius: 0px;
			line-height: 38px;
			width: 100%;
			text-transform: uppercase;
			border: none;
			&:hover{
				&:before{
					left: 370px;
				}
			}
		}
		a{
			font-size: 14px;
			color: $text-color;
			// font-family: $primary-font;
			margin-top: 20px;
			display: block;
		}
	}
}
/* End Login form Area css
============================================================================================ */