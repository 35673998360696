/*=================== header area css start ====================*/
.search-field {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  height: 89px;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -ms-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  &.active{
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  .cls {
    position: absolute;
    right: 10px;
    top: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #000;
    font-size: 24px;
  }

  form {
    width: 100%;

    input {
      text-align: center;
      border: none;
      outline: none;
      font-family: $title-font;
      font-size: 18px;
      width: 100%;

      &::placeholder {
        font-style: italic;
      }

    }
  }
}

.header_area {
  position: relative;
  width: 100%;
  z-index: 1040;
  transition: background 0.4s, all 0.3s linear;
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.2);

  .navbar {
    background: transparent;
    padding: 0px;
    border: 0px;
    border-radius: 0px;

    .nav {
      .nav-item {
        margin-right: 30px;

        .nav-link {
          // font: 600 12px/80px $title-font;
          text-transform: capitalize;
          color: $title-color;
          font-family: $title-font;
          //   padding: 32px 0px;
          display: inline-block;
          @media(min-width: 991px) {
            padding: 32px 0px;
          }

          &:after {
            display: none;
          }
        }

        &:hover,
        &.active {
          .nav-link {
            color: $primary-color;
          }
        }

        &.submenu {
          position: relative;

          ul {
            border: none;
            padding: 0px;
            border-radius: 0px;
            box-shadow: none;
            margin: 0px;
            background: #fff;
            @media (min-width: 992px) {
              position: absolute;
              top: 120%;
              left: 0px;
              min-width: 200px;
              text-align: left;
              opacity: 0;
              transition: all 300ms ease-in;
              visibility: hidden;
              display: block;
              border: none;
              padding: 0px;
              border-radius: 0px;
            }

            &:before {
              content: "";
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 10px 10px 0 10px;
              border-color: #eeeeee transparent transparent transparent;
              position: absolute;
              right: 24px;
              top: 45px;
              z-index: 3;
              opacity: 0;
              transition: all 400ms linear;
            }

            .nav-item {
              display: block;
              float: none;
              margin-right: 0px;
              border-bottom: 1px solid #ededed;
              margin-left: 0px;
              transition: all 0.4s linear;

              .nav-link {
                line-height: 45px;
                color: $title-color;
                padding: 0px 30px;
                transition: all 150ms linear;
                display: block;
                margin-right: 0px;
              }

              &:last-child {
                border-bottom: none;
              }

              &:hover {
                .nav-link {
                  background: $primary-color;
                  color: #fff;
                }
              }
            }
          }

          &:hover {
            ul {
              @media (min-width: 992px) {
                visibility: visible;
                opacity: 1;
                top: 100%;
              }

              .nav-item {
                margin-top: 0px;
              }
            }
          }
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .search {
      font-size: 12px;
      line-height: 60px;
      display: inline-block;
      color: $title-color;

      i {
        font-weight: 600;
      }
    }
  }

  &.navbar_fixed {
    .main_menu {
      position: fixed;
      width: 100%;
      top: -70px;
      left: 0;
      right: 0;
      background: #ffffff;
      @include transform(translateY(70px));
      @include transition();
      box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);

      .navbar {
        .nav {
          .nav-item {
            .nav-link {
              line-height: 70px;
            }
          }
        }
      }
    }
  }
}

#search_input_box {
  // @extend .gradient-bg;
  text-align: center;
  padding: 5px 20px;

  .form-control {
    padding-left: 0;
    background: transparent;
    border: 0;
    color: #ffffff;
    font-weight: 600;
    font-size: 18px;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  input {
    @include placeholder {
      color: #ffffff;
      font-size: 16px;
    }
  }

  .btn {
    width: 0;
    height: 0;
    padding: 0;
    border: 0;
  }

  .lnr-cross {
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    padding: 10px 0;
  }
}

.header-top {
  background: #000000;
  @media (max-width: 991px) {
    display: none;
  }

  a {
    color: #fff;
  }

  ul li {
    display: inline-block;
    margin-left: 15px;
  }

  .header-top-left {
    a {
      margin-right: 60px;
      font-weight: 600;

      .text {
        margin-top: -2px;

        span {
          font-size: 14px;
          color: #ffffff;
        }
      }

      .lnr {
        color: #ffffff;
        margin-right: 8px;
      }
    }

    @media(max-width: 767px) {
      .text {
        display: none;
      }
    }
  }

  .header-top-right {
    text-align: right;

    a {
      padding: 0 20px;
      height: 40px;
      display: inline-block;
      line-height: 40px;
      font-size: 14px;
      font-weight: 600;
      font-family: $title-font;
      background: transparent;
      @include transition();

      &:hover {
        // @extend .gradient-bg;
      }
    }
  }
}

.nav-shop {
  .nav-item {
    &.lang {
      display: inline-flex;

      a {
        color: #222;
        text-transform: uppercase;
        font-family: $title-font;
        font-size: 14px;
        margin: 0 2px;
        padding: 0 2px;
        -webkit-transition: .2s;
        -moz-transition: .2s;
        -ms-transition: .2s;
        -o-transition: .2s;
        transition: .2s;

        &.active:hover,
        &.active {
          color: $primary-color;
        }

        &:hover {
          color: $primary-color-blurry;
        }
      }
    }
  }

  li {
    display: inline-block;
    margin-right: 15px;

    i, span {
      font-size: 16px;
      color: $title-color;
    }

    a,
    button {
      padding: 0;
      border: 0;
      background: transparent;
      position: relative;

      .nav-shop__circle {
        font-size: 9px;
        display: inline-block;
        background: $primary-color;
        color: #fff;
        padding: 0px 5px;
        border-radius: 50%;
        position: absolute;
        top: -8px;
        right: -12px;
      }
    }

    &:last-child {
      margin-left: 20px;
    }
  }
}

/*=================== header area css end ====================*/
